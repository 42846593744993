import { handleErrorWithSentry, Replay } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'
import { getConfig } from './store/config'

const { ENVIRONMENT } = getConfig()

Sentry.init({
	dsn: 'https://7cc5ff68f1db43369a95fcf9d912e900@sentry.scientiamobile.com/46',
	environment: ENVIRONMENT,
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [new Replay()],
	enabled: ENVIRONMENT !== 'development',
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
